import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from '@styles/Socials.module.css'

const { container } = classes;



const Socials = () => {

    const handleInstagramClick = () => window.open('https://www.instagram.com/diablink.app', '_blank')
    //
    return (
        <div className={container}>
            <FontAwesomeIcon size={"2xl"} icon={faInstagram} onClick={() => handleInstagramClick()} />

        </div>
    )
}

export default Socials