import classes from '@styles/Privacy.module.css'
import React from 'react';
import AppMail from '../components/AppMail/AppMail';
import TableOfContents from '../components/Privacy/TableOfContents';
import Section from '@components/Privacy/Section';

const { container, contents, bold } = classes;

const Privacy = () => {
    const oneRef = React.useRef<HTMLDivElement>(null);
    const twoRef = React.useRef<HTMLDivElement>(null);
    const threeRef = React.useRef<HTMLDivElement>(null);
    const fourRef = React.useRef<HTMLDivElement>(null);
    const fiveRef = React.useRef<HTMLDivElement>(null);
    const sixRef = React.useRef<HTMLDivElement>(null);
    const sevenRef = React.useRef<HTMLDivElement>(null);
    const eightRef = React.useRef<HTMLDivElement>(null);

    const sections = [
        { label: 'Podaci koje prikupljamo', ref: oneRef },
        { label: 'Kako koristimo vaše podatke', ref: twoRef },
        { label: 'Sigurnost podataka', ref: threeRef },
        { label: 'Prava korisnika', ref: fourRef },
        { label: 'Korišćenje kolačića i sličnih tehnologija', ref: fiveRef },
        { label: 'Čuvanje podataka', ref: sixRef },
        { label: 'Izmene politike privatnosti', ref: sevenRef },
        { label: 'Kontakt', ref: eightRef },
    ];

    return (
        <div className={container}>

            <TableOfContents sections={sections} />

            <div className={contents}>
                <h1> Politika privatnosti</h1>
                <div>
                    <h2>Datum stupanja na snagu: 3. Oktobar 2024.</h2>
                    <p>
                        Diablink poštuje vašu privatnost i posvećen je zaštiti vaših ličnih podataka. Ova politika privatnosti objašnjava kako prikupljamo, koristimo, i čuvamo vaše informacije kada koristite našu aplikaciju.
                    </p>
                </div>

                <Section refProp={oneRef} title='1. Podaci koje prikupljamo'>
                    <p>
                        Koristimo različite vrste podataka kako bismo obezbedili funkcionalnost aplikacije i poboljšali vaše korisničko iskustvo. To uključuje:
                    </p>
                    <ul>
                        <li>
                            <span className={bold}>Lični podaci</span>: Kada kreirate nalog, možemo prikupljati vaše ime, e-mail adresu i druge identifikacione informacije.
                        </li>

                        <li>
                            <span className={bold}>Zdravstveni podaci</span>: Diablink može prikupljati informacije o vašim nivoima šećera u krvi, unosu hrane, lekovima i druge podatke koji su relevantni za upravljanje dijabetesom. Ovi podaci su strogo poverljivi i koriste se isključivo za pružanje usluga unutar aplikacije.
                        </li>

                        <li>
                            <span className={bold}>Tehnički podaci</span>: Prikupljamo informacije o uređaju koji koristite (npr. tip uređaja, operativni sistem) i informacije o načinu na koji koristite aplikaciju (npr. koje funkcije koristite).
                        </li>
                    </ul>

                </Section>


                <Section title='2. Kako koristimo vase podatke' refProp={twoRef}>
                    <p>Vaši podaci se koriste isključivo za sledeće svrhe:</p>
                    <ul>
                        <li>Omogućavanje funkcionalnosti aplikacije.</li>
                        <li>Personalizaciju vašeg iskustva korišćenja aplikacije.</li>
                        <li>Poboljšanje i razvoj novih funkcionalnosti.</li>
                        <li>Komunikaciju sa vama u vezi sa novostima, ažuriranjima ili pitanjima vezanim za aplikaciju.</li>
                    </ul>
                    Vaše podatke nećemo deliti sa trećim stranama, osim u sledećim slučajevima:
                    <ul>
                        <li>Ako to zakon zahteva.</li>
                        <li>Ako je to potrebno za zaštitu prava ili sigurnosti korisnika ili drugih osoba.</li>
                    </ul>
                </Section>


                <Section refProp={threeRef} title='3. Sigurnost podataka'>
                    <p>
                        Preduzimamo odgovarajuće tehničke i organizacione mere kako bismo zaštitili vaše podatke od neovlašćenog pristupa, gubitka, uništenja ili izmene. Međutim, nijedan sistem za prenos podataka preko interneta ili metoda skladištenja nije 100% siguran. Radimo sve što je u našoj moći da vaši podaci budu zaštićeni, ali ne možemo garantovati apsolutnu sigurnost.
                    </p>
                </Section>

                <Section refProp={fourRef} title='4. Prava korisnika'>
                    <p>Imate pravo da: </p>
                    <ul>
                        <li>Pristupite svojim ličnim podacima koje prikupljamo.</li>
                        <li>Ispravite netačne ili nepotpune informacije.</li>
                        <li>Zatražite brisanje svojih podataka.</li>
                        <li>Ograničite ili prigovorite na obradu vaših podataka.</li>
                    </ul>
                    <p>Ako želite da ostvarite neko od ovih prava, možete nas kontaktirati putem e-pošte na: <AppMail /></p>
                </Section>

                <Section title='5. Korišćenje kolačića i sličnih tehnologija' refProp={fiveRef}>
                    <p>
                        Diablink može koristiti kolačiće i slične tehnologije kako bi unapredio funkcionalnost aplikacije. Ove tehnologije mogu prikupljati informacije o vašim interakcijama sa aplikacijom kako bi vam omogućile bolje korisničko iskustvo.
                    </p>
                </Section>

                <Section title='6. Čuvanje podataka' refProp={sixRef}>
                    <p>
                        Vaše podatke čuvamo onoliko dugo koliko je potrebno da vam pružimo usluge ili kako to zakon nalaže. Kada vaši podaci više nisu potrebni, brišemo ih ili ih anonimizujemo.
                    </p>
                </Section>

                <Section title='7. Izmene politike privatnosti' refProp={sevenRef}>
                    <p>
                        Ova politika privatnosti može biti povremeno ažurirana kako bismo odražavali promene u načinu na koji prikupljamo i koristimo podatke. Obavestićemo vas o bilo kakvim značajnim promenama putem aplikacije ili e-pošte. Preporučujemo vam da povremeno pregledate ovu politiku.
                    </p>
                </Section>

                <Section title='8. Kontakt' refProp={eightRef}>
                    <p>
                        Ako imate bilo kakva pitanja ili nedoumice u vezi sa ovom politikom privatnosti, možete nas kontaktirati na: <AppMail />
                    </p>
                </Section>
            </div>
        </div>
    )
}

export default Privacy