import classes from '../styles/Footer.module.css'
import logo from '../assets/images/logo.png'
import { useNavigate } from 'react-router-dom';
import { MouseEvent } from 'react';
import Socials from './Socials';
const { container, appLink, appButton, links, blue, bold, clickableLinks } = classes;

const Footer = () => {

    const navigate = useNavigate();

    const navigateTo = (link: string, e: MouseEvent) => {
        e.preventDefault();
        navigate(link);
    }

    const handleAppClick = () => {
        window.alert('Aplikacija je jos uvek u izradi')
    }

    return (
        <>
            <hr />
            <div className={container}>

                <div className={appLink}>
                    <img src={logo} alt="" width={50} />
                    <h3>Pomazemo vam da kontrolisete dijabetes.</h3>
                    {/* App Button */}
                    <div className={appButton} onClick={handleAppClick}>
                        <button>Preuzmite</button>
                    </div>
                    <Socials />
                </div>

                <div className={`${links}`}>
                    <p className={`${blue} ${bold}`}>Linkovi</p>
                    <div className={clickableLinks}>
                        <p onClick={(e) => navigateTo("/privacy", e)}>Privatnost</p>
                        <p onClick={(e) => navigateTo("/terms", e)}>Koriscenje</p>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Footer