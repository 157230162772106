import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsStaggered } from '@fortawesome/free-solid-svg-icons';
import classes from '../../styles/Privacy.module.css'
const { tableOfContents } = classes;

interface TableOfContentsProps {
    sections: { label: string; ref: React.RefObject<HTMLDivElement> }[];
}

const TableOfContents: React.FC<TableOfContentsProps> = ({ sections }) => {


    return (
        <div className={tableOfContents}>
            <p>
                <FontAwesomeIcon icon={faBarsStaggered} /> Tabela sadrzaja
            </p>
            {sections.map((section, index) => (
                <p
                    key={index}
                    onClick={() =>
                        section.ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
                    }
                >
                    {index + 1}. {section.label}
                </p>
            ))}
        </div>
    );
};

export default TableOfContents;
