import { useAuthModal } from '@/context/authModalCtx';
import logo from '../assets/images/logo.png';
import classes from '../styles/Home.module.css';
import AuthModal from '@/components/AuthModal';

const { container, heroSection, welcomeText, logoImage, thankyou } = classes;

const Home = () => {
    const { isOpen, setIsOpen } = useAuthModal();


    return (
        <div className={`${container}`}>
            {isOpen && <AuthModal />}

            <section className={`${heroSection}`}>
                <div className={`${welcomeText}`}>
                    <h1>Tamo gde vi vladate dijabetesom </h1>
                    <h3>
                        Znamo koliko je izazovno živeti sa dijabetesom. Pomazemo vam da pratite nivo šećera u krvi, obroke i napredak – sve na jednom mestu, prilagođenom vama.
                    </h3>
                </div>
                <div>
                    <img src={logo} alt="" className={`${logoImage}`} />
                </div>
            </section>

            <div className={thankyou}>
                <p>Hvala sto ste uz nas!</p>
                <p>Vaša podrška znači mnogo i motiviše nas da nastavimo sa radom na poboljšanju iskustva i pomoći onima kojima je najpotrebnija.</p>
            </div>

        </div>
    )
}

export default Home

// Tamo gde vi vladate dijabetesom
