import React from 'react';

interface SectionProps {
    title: string;
    refProp: React.RefObject<HTMLDivElement>;
    children: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({ title, refProp, children }) => {
    return (
        <div ref={refProp}>
            <h3>{title}</h3>
            {children}
        </div>
    );
};

export default Section;
