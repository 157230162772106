import classes from './AppMail.module.css'
const { link } = classes;

const AppMail = () => {
    return (
        <a href="mailto:diablinkapp@gmail.com" className={link}>
            diablinkapp@gmail.com
        </a>
    )
}

export default AppMail