import { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";

const initialState = {
    isOpen: false,
    setIsOpen: (isOpen: boolean) => { }
}

const AuthModalContext = createContext(initialState);

export const useAuthModal = () => {
    const context = useContext(AuthModalContext);
    if (context === undefined) throw new Error("useAuthModal must be used within an AuthModalProvider");
    return context;
};

export const AuthModalProvider = ({ children }: PropsWithChildren) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        // disable scroll on body
        if (isOpen) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = '';
    }, [isOpen]);

    const value = {
        isOpen,
        setIsOpen
    };

    return (
        <AuthModalContext.Provider value={value}>
            {children}
        </AuthModalContext.Provider>
    );
};