import { useAuthModal } from '@/context/authModalCtx';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';


const AuthModalHandler = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { isOpen, setIsOpen } = useAuthModal();

    useEffect(() => {
        const status = searchParams.get('status');

        if (status === 'error') {
            setIsOpen(true);
        } else if (status === 'success') {
            // Clear params if needed or close the modal, etc.
            setSearchParams({});
        }
    }, [searchParams, setSearchParams, isOpen, setIsOpen]);

    return null;
}

export default AuthModalHandler