
import { useAuthModal } from '@/context/authModalCtx'
import { getApiBase } from '@/services/api'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from '@styles/AuthModal.module.css'

const { container, modal, modalRow, authProviders, modalColumn } = classes


const AuthModal = () => {
    const { isOpen, setIsOpen } = useAuthModal();

    const authWithGoogle = () => {
        window.open(`${getApiBase()}/auth/google/go`, '_blank');
    }


    return (
        <div className={container}>

            <div className={modal}>
                <div className={modalRow}>
                    <FontAwesomeIcon
                        onClick={() => setIsOpen(false)}
                        icon={faClose}
                        size={"xl"}
                        style={{ cursor: 'pointer' }}
                        className={classes.closeIcon}
                    />
                </div>

                <div className={modalRow}>
                    <div className={modalColumn}>
                        <h2 onClick={() => setIsOpen(false)}>Prijavite se pomoću:</h2>

                        <div className={`${authProviders}`}>
                            <button onClick={authWithGoogle}>Google</button>
                            <button>Facebook</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthModal