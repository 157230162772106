import axios from 'axios';

const inProduction = process.env.NODE_ENV === 'production';
const productionUrl = 'https://api.diablink.net/api';
const developmentUrl = 'http://localhost:3000/api';
let apiBase = inProduction ? productionUrl : developmentUrl;

export const ApiHandler = axios.create({
    baseURL: apiBase, // Replace with your ApiHandler base URL
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
});

export const getApiBase = () => {
    const inProduction = process.env.NODE_ENV === 'production';
    const productionUrl = 'https://api.diablink.net/api';
    const developmentUrl = 'http://localhost:3000/api';
    let apiBase = inProduction ? productionUrl : developmentUrl;
    return apiBase;
}