import { faHome } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classes from './HomeButton.module.css'
import { useLocation, useNavigate } from "react-router-dom"


const HomeButton = () => {

  const navigate = useNavigate();

  const location = useLocation();
  if (location.pathname === '/') return null;

  return (
    <div className={`${classes.container}`} onClick={() => navigate('/')}>
      <FontAwesomeIcon color="white" size={"xl"} icon={faHome} />
    </div>
  )
}

export default HomeButton