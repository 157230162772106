
import { useAuth } from '@/context/authCtx';
import classes from '../styles/Navbar.module.css';
import { useState } from 'react';
import AuthModal from './AuthModal';
import { useAuthModal } from '@/context/authModalCtx';
import { faDashboard, faSignIn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const { container, navbar, brand, appButton } = classes

const Navbar = () => {

    const { isOpen: isModalOpen, setIsOpen: setIsModalOpen } = useAuthModal();
    const { user } = useAuth();
    const handleAppClick = () => {
        if (!user) setIsModalOpen(true);
        else {
            console.log("User is signed in");
        }
    }

    return (
        <div className={`${container}`}>
            {isModalOpen && <AuthModal />}

            <nav className={navbar}>
                {/* Brand */}
                <div className={`${brand}`}>
                    <p>Diablink</p>
                </div>

                {/* Nav Button */}
                <div className={appButton} onClick={handleAppClick}>
                    <button>
                        {user ? <FontAwesomeIcon icon={faDashboard} /> : <FontAwesomeIcon icon={faSignIn} />}
                    </button>
                </div>
            </nav>
        </div>
    )

}

export default Navbar