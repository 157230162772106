import { useNavigate } from "react-router-dom"
import classes from '../styles/NotFound.module.css'
const { container, lower } = classes;

const NotFound = () => {
    const navigate = useNavigate()

    return (
        <div className={`${container}`}>
            <p>Oops, stranica nije pronađena.</p>
            <p className={`${lower}`}>Izgleda da je ova stranica otišla da gricne nešto... 🥐 Ne brini, vratićemo te na pravi put.</p>
            <button onClick={() => navigate('/')}>Pocetna</button>
        </div>
    )
}

export default NotFound