import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomeButton from './components/BackHome/HomeButton';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import ScrollToTop from './components/ScrollToTop';
import { AuthProvider } from './context/authCtx';
import { AuthModalProvider } from './context/authModalCtx';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Privacy from './pages/Privacy';
import AuthModalHandler from './components/AuthModalHandler';

function App() {

  return (
    <BrowserRouter>
      <AuthProvider>
        <AuthModalProvider>
          <AuthModalHandler />

          <ScrollToTop />
          <Navbar />

          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/privacy' element={<Privacy />} />
            <Route path="*" element={<NotFound />} />
          </Routes>

          <Footer />
          <HomeButton />

        </AuthModalProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
